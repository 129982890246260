// context API adapter | https://dev.to/papercoding22/adapter-pattern-with-react-29bg

import React, { createContext, useContext } from 'react';

const AdapterContext = createContext();

const createAuthAdapter = (authService) => ({
  login: async (email, password) => {
    const result = await authService.login(email, password);
    return result;
  },

  register: async (email, password) => {
    const result = await authService.register(email, password);
    return result;
  },
});

const createAuthService = () => ({
  login: async (email, password) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email, password: password })
    };
    const response = await fetch('/api/login', requestOptions);
    const result = await response.json();
    return result;
  },

  register: async (email, password) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email, password: password })
    };
    const response = await fetch('/api/register', requestOptions);
    const result = await response.json();
    return result;
  },
});

export const AdapterProvider = ({ children }) => {
  const authService = createAuthService();
  const authAdapter = createAuthAdapter(authService);

  return (
    <AdapterContext.Provider value={authAdapter}>
      {children}
    </AdapterContext.Provider>
  );
};

export const useAuthAdapter = () => {
  const adapter = useContext(AdapterContext);
  if (!adapter) {
    throw new Error('useAuthAdapter must be used within AdapterProvider');
  }
  return adapter;
};
